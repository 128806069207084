@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "../../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.carousel1 {
	display: none;
}
@media (max-width: $md) {
	.carousel1 {
		width: 100%;
		height: 350px;
		position: absolute;
		left: 0%;
		top: 20%;
		display: block;
		.slide {
			transform: scale(0.9);
			transition: transform 300ms;
			opacity: 0.7;
			position: relative;
			overflow: hidden;
			box-shadow: 0 3px 10px 2px #000;
			img {
				width: 100%;
				height: 340px;
				object-fit: cover;
				margin: 0 auto;
				border-radius: $br_r_0;
			}
			.link {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 20%;
				width: 100%;
				text-align: center;
				color: $light;
				position: absolute;
				bottom: -30%;
				left: 0%;
				font-size: 1rem;
				text-shadow: 0px 0px 5px #000, 2px 2px 5px #000, -1px -1px 5px #000;
				padding-bottom: 20px;
				&:hover {
					transform: scale(1.07);
					background-image: linear-gradient(
						to bottom,
						rgba(0, 0, 0, 0),
						rgba(0, 0, 0, 0.9)
					);
				}
			}
		}
		.active {
			transform: scaleX(1);
			opacity: 1;
			img {
				width: 100%;
				z-index: 123;
				object-fit: cover;
			}
			.link {
				bottom: 0%;
				left: 0%;
				background-image: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0),
					rgba(0, 0, 0, 0.9)
				);
			}
		}
		.arrow {
			position: absolute;
			cursor: pointer;
			z-index: 12;
			width: 10%;
			text-align: center;
			transition: 0.1ms;
			bottom: 40%;
			font-size: 1.7rem;
			&:hover {
				color: $primary;
				transition: 0.1ms;
			}
		}
		.next {
			right: 2%;
		}
		.prev {
			left: 2%;
		}
	}
}
@media (max-width: $sm) {
	.carousel1 {
		width: 100%;
		height: 260px;
		position: absolute;
		left: 0%;
		top: 35%;
		.slide {
			transform: scale(0.9);
			transition: transform 300ms;
			opacity: 0.6;
			position: relative;
			overflow: hidden;
			img {
				width: 100%;
				height: 250px;
				object-fit: cover;
				margin: 0 auto;
				border-radius: $br_r_0;
			}
			.link {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 20%;
				width: 100%;
				text-align: center;
				color: $light;
				position: absolute;
				bottom: -30%;
				left: 0%;
				font-size: 0.9rem;
				line-height: 13px;
				// text-shadow: 0px 0px 5px #000, 2px 2px 5px #000, -1px -1px 5px #000;
				transition: all 0.5s;
				&:hover {
					transform: scale(1.07);
				}
			}
		}
		.active {
			transform: scaleX(1);
			opacity: 1;
			img {
				width: 100%;
				z-index: 123;
				object-fit: cover;
			}
			.link {
				bottom: 0%;
				left: 0%;
			}
		}
		.arrow {
			position: absolute;
			cursor: pointer;
			z-index: 12;
			width: 10%;
			text-align: center;
			transition: 0.1ms;
			bottom: 40%;
			font-size: 1.7rem;
			&:hover {
				color: $primary;
				transition: 0.1ms;
			}
		}
		.next {
			right: 2%;
		}
		.prev {
			left: 2%;
		}
	}
}
