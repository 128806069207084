@import "../../scss/varuables.scss";
.ques_ansDiv {
	// padding: 0;
	// margin: 0;
}
.testimonials {
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	padding: 4rem 0;
	.left_t {
		width: 58%;
		display: flex;
		gap: 2rem;
		flex-direction: column;
		text-transform: uppercase;
		// color: $light;
		padding: 2rem 0 0 0;
		position: relative;
		z-index: 1;
		& > :nth-child(1) {
			font-weight: bold;
			font-size: 50px;
			position: absolute;
		}
		&_textsDiv {
			overflow: hidden;
			box-shadow: 0 5px 15px 10px rgba(0, 0, 0, 0.345);
			background: linear-gradient(to right, #262745, #1d1f35);
			width: 140%;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			position: absolute;
			top: 7rem;
			right: 0;
			padding: 10% 10% 10% 40%;
			color: $light;
			& > :nth-child(1) {
				font-weight: normal;
				font-size: 25px;
				font-weight: 600;
			}
			& > :nth-child(2) {
				font-size: 14px;
				text-transform: none;
				text-align: justify;
				letter-spacing: 0px;
				line-height: 22px;
			}
			.arrows {
				margin-top: 1.5rem;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 2rem;
				&_left {
					position: relative;
					cursor: pointer;
					transition: 0.3s;
					&:hover {
						svg {
							background: $primary;
							color: $light;
							box-shadow: none;
						}
					}
					&:hover::after {
						transition: 0.3s;
						left: 0px;
						bottom: 0px;
					}
				}
				&_left::after {
					transition: 0.3s;
					content: "after";
					color: transparent;
					width: 100%;
					height: 100%;
					position: absolute;
					left: 5px;
					bottom: -3px;
					z-index: 1;
					background: $primary;
					box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.417);
				}
				&_right {
					position: relative;
					cursor: pointer;
					&:hover {
						svg {
							background: $primary;
							color: $light;
							box-shadow: none;
						}
					}
					&:hover::after {
						transition: 0.3s;
						right: 0px;
						bottom: 0px;
					}
					svg {
						box-shadow: 2px -3px 15px rgba(0, 0, 0, 0.804);
					}
				}
				&_right::after {
					content: "after";
					color: transparent;
					width: 100%;
					height: 100%;
					position: absolute;
					right: 5px;
					bottom: -3px;
					z-index: 1;
					background: $primary;
					box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.417);
				}
				svg {
					transition: 0.3s;
					background: $light;
					width: 4.5rem;
					font-size: 2rem;
					color: $primary;
					position: relative;
					z-index: 12;
					box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.804);
				}
			}
		}
	}
	.right_t {
		position: relative;
		width: 400px;
		height: 390px;
		z-index: 123;
		transform: translateZ(50px);
		&:hover {
			cursor: pointer;
			transform: translateZ(50px) scale(1.05);
		}
		img {
			position: absolute;
			top: 1rem;
			left: 1rem;
			z-index: 1234;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 12;
			box-shadow: 5px 3px 35px rgba(0, 0, 0, 0.781);
			transform: translateZ(100px);
		}
		&_div1 {
			border: 2px solid $primary;
			position: absolute;
			top: 0rem;
			left: 0rem;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 1;
			box-shadow: -5px 3px 35px rgba(0, 0, 0, 0.781);
		}
		&_div2 {
			background: linear-gradient(to bottom left, #178187, #319ba1, #48e2ea);
			position: absolute;
			right: -2.2rem;
			bottom: -2.2rem;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 2;
			box-shadow: 3px 3px 35px rgba(0, 0, 0, 0.781);
		}

		@media (min-width: $xxl) {
			width: 520px;
			height: 450px;
		}
	}
}

@media (max-width: $xl) {
	.testimonials {
		display: flex;
		justify-content: space-between;
		gap: 1rem;
		padding: 4rem 0 5rem 0;
		.left_t {
			width: 58%;
			display: flex;
			gap: 2rem;
			flex-direction: column;
			text-transform: uppercase;
			// color: $light;
			padding: 2rem 0 0 0;
			position: relative;
			& > :nth-child(1) {
				font-weight: bold;
				font-size: 35px;
				position: absolute;
				top: 5%;
				left: -90%;
				text-align: center;
				width: 200%;
				margin-right: 10rem;
			}
			&_textsDiv {
				text-align: center;
				overflow: hidden;
				box-shadow: 0 5px 15px 10px rgba(0, 0, 0, 0.345);

				width: 140%;
				display: flex;
				flex-direction: column;
				gap: 1rem;
				position: absolute;
				top: 7rem;
				right: 0;
				padding: 10% 10% 10% 50%;
				& > :nth-child(1) {
					font-weight: normal;
					font-size: 20px;
				}
				& > :nth-child(2) {
					font-size: 13px;
					text-transform: none;
					text-align: justify;
					letter-spacing: 0px;
					line-height: 15px;
				}
				.arrows {
					// border: 1px solid red;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 2rem;
					&_left {
						position: relative;
						cursor: pointer;
						transition: 0.3s;
						&:hover {
							svg {
								background: $primary;
								// color: $light;
							}
						}
						&:hover::after {
							transition: 0.3s;
							left: 0px;
							bottom: 0px;
						}
					}
					&_left::after {
						transition: 0.3s;
						content: "after";
						color: transparent;
						width: 100%;
						height: 100%;
						position: absolute;
						left: 5px;
						bottom: -3px;
						z-index: 1;
						background: $primary;
					}
					&_right {
						position: relative;
						cursor: pointer;
						&:hover {
							svg {
								background: $primary;
								// color: $light;
							}
						}
						&:hover::after {
							transition: 0.3s;
							right: 0px;
							bottom: 0px;
						}
					}
					&_right::after {
						content: "after";
						color: transparent;
						width: 100%;
						height: 100%;
						position: absolute;
						right: 5px;
						bottom: -3px;
						z-index: 1;
						background: $primary;
					}
					svg {
						transition: 0.3s;
						background: $light;
						width: 4.5rem;
						font-size: 2rem;
						color: $primary;
						position: relative;
						z-index: 12;
					}
				}
			}
		}

		.right_t {
			position: relative;
			width: 300px;
			height: 290px;
			margin-top: 10rem;
			img {
				position: absolute;
				top: 1rem;
				left: 1rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 12;
			}
			&_div1 {
				border: 2px solid $primary;
				position: absolute;
				top: 0rem;
				left: 0rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 1;
			}
			&_div2 {
				background: linear-gradient(to bottom left, #178187, #319ba1, #48e2ea);
				position: absolute;
				right: -2.2rem;
				bottom: -2.2rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 2;
			}
		}
		// .right_t::after {
		// 	content: "after";
		// 	color: transparent;
		// 	background: linear-gradient(to bottom left, #178187, #319ba1, #48e2ea);
		// 	position: relative;
		// 	width: 400px;
		// 	height: 390px;
		// 	position: absolute;
		// 	right: -2.2rem;
		// 	bottom: -2.2rem;
		// 	z-index: 1;
		// }
	}
}
@media (max-width: $md) {
	.testimonials {
		height: 600px;
		display: flex;
		justify-content: space-between;
		gap: 1rem;
		padding: 0 0 5rem 0;
		position: relative;
		.left_t {
			width: 58%;
			display: flex;
			gap: 2rem;
			flex-direction: column;
			text-transform: uppercase;
			// color: $light;
			padding: 2rem 0 0 0;
			position: absolute;
			right: 0;
			top: 10%;
			& > :nth-child(1) {
				font-weight: bold;
				font-size: 30px;
				position: absolute;
				top: 100%;
				left: -90%;
				text-align: center;
				width: 200%;
				margin-right: 10rem;
			}
			&_textsDiv {
				text-align: center;
				overflow: hidden;
				width: 140%;
				display: flex;
				flex-direction: column;
				gap: 1rem;
				position: absolute;
				top: 7rem;
				right: 0;
				padding: 10% 10% 10% 30%;
				& > :nth-child(1) {
					font-weight: normal;
					font-size: 20px;
				}
				& > :nth-child(2) {
					font-size: 13px;
					text-transform: none;
					text-align: justify;
					letter-spacing: 0px;
					line-height: 15px;
				}
				.arrows {
					// border: 1px solid red;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 2rem;
					&_left {
						position: relative;
						cursor: pointer;
						transition: 0.3s;
						&:hover {
							svg {
								background: $primary;
								// color: $light;
							}
						}
						&:hover::after {
							transition: 0.3s;
							left: 0px;
							bottom: 0px;
						}
					}
					&_left::after {
						transition: 0.3s;
						content: "after";
						color: transparent;
						width: 100%;
						height: 100%;
						position: absolute;
						left: 5px;
						bottom: -3px;
						z-index: 1;
						background: $primary;
					}
					&_right {
						position: relative;
						cursor: pointer;
						&:hover {
							svg {
								background: $primary;
								// color: $light;
							}
						}
						&:hover::after {
							transition: 0.3s;
							right: 0px;
							bottom: 0px;
						}
					}
					&_right::after {
						content: "after";
						color: transparent;
						width: 100%;
						height: 100%;
						position: absolute;
						right: 5px;
						bottom: -3px;
						z-index: 1;
						background: $primary;
					}
					svg {
						transition: 0.3s;
						background: $light;
						width: 4.5rem;
						font-size: 2rem;
						color: $primary;
						position: relative;
						z-index: 12;
					}
				}
			}
		}

		.right_t {
			position: absolute;
			top: 10%;
			left: -11%;
			width: 200px;
			height: 190px;
			margin-top: 10rem;
			img {
				position: absolute;
				top: 1rem;
				left: 1rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 12;
			}
			&_div1 {
				border: 2px solid $primary;
				position: absolute;
				top: 0rem;
				left: 0rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 1;
			}
			&_div2 {
				background: linear-gradient(to bottom left, #178187, #319ba1, #48e2ea);
				position: absolute;
				right: -2.2rem;
				bottom: -2.2rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 2;
			}
		}
	}
}
@media (max-width: $sm) {
	.testimonials {
		height: 825px;
		display: flex;
		justify-content: space-between;
		gap: 1rem;
		padding: 0 0 10rem 0;
		position: relative;
		.left_t {
			width: 58%;
			display: flex;
			gap: 2rem;
			flex-direction: column;
			text-transform: uppercase;
			// color: $light;
			padding: 2rem 0 0 0;
			position: absolute;
			right: 0;
			top: 10%;
			& > :nth-child(1) {
				font-weight: 700;
				font-size: 26px;
				position: absolute;
				top: -50%;
				left: -90%;
				text-align: center;
				width: 200%;
				margin-right: 10rem;
			}
			&_textsDiv {
				text-align: center;
				overflow: hidden;
				box-shadow: 0 5px 15px 10px rgba(0, 0, 0, 0.345);
				background: linear-gradient(to right, #27283a, #161727);
				width: 175%;
				display: flex;
				flex-direction: column;
				gap: 1rem;
				position: absolute;
				top: 2.5rem;
				right: 0;
				padding: 15%;
				& > :nth-child(1) {
					font-weight: 700;
					font-size: 16px;
				}
				& > :nth-child(2) {
					font-size: 14px;
					text-transform: none;
					text-align: center;
					letter-spacing: 0.3px;
					line-height: 15px;
				}
				.arrows {
					// border: 1px solid red;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 2rem;
					&_left {
						position: relative;
						cursor: pointer;
						transition: 0.3s;
						&:hover {
							svg {
								background: $primary;
								// color: $light;
							}
						}
						&:hover::after {
							transition: 0.3s;
							left: 0px;
							bottom: 0px;
						}
					}
					&_left::after {
						transition: 0.3s;
						content: "after";
						color: transparent;
						width: 100%;
						height: 100%;
						position: absolute;
						left: 5px;
						bottom: -3px;
						z-index: 1;
						background: $primary;
					}
					&_right {
						position: relative;
						cursor: pointer;
						&:hover {
							svg {
								background: $primary;
								// color: $light;
							}
						}
						&:hover::after {
							transition: 0.3s;
							right: 0px;
							bottom: 0px;
						}
					}
					&_right::after {
						content: "after";
						color: transparent;
						width: 100%;
						height: 100%;
						position: absolute;
						right: 5px;
						bottom: -3px;
						z-index: 1;
						background: $primary;
					}
					svg {
						transition: 0.3s;
						background: $light;
						width: 4.5rem;
						font-size: 2rem;
						color: $primary;
						position: relative;
						z-index: 12;
					}
				}
			}
		}
		.right_t {
			// display: none;
			position: absolute;
			top: auto;
			left: 1%;
			bottom: 7%;
			width: 300px;
			height: 230px;
			margin-top: 10rem;
			z-index: 123;
			img {
				position: absolute;
				top: 1rem;
				left: 1rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 12;
			}
			&_div1 {
				border: 2px solid $primary;
				position: absolute;
				top: 0rem;
				left: 0rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 1;
			}
			&_div2 {
				background: linear-gradient(to bottom left, #178187, #319ba1, #48e2ea);
				position: absolute;
				right: -2.2rem;
				bottom: -2.2rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 2;
			}
		}
	}
}
