@import "../../scss/varuables.scss";

.fundsCalculate {
	// background: $bg;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding-bottom: 10%;
	margin-top: 150px;
	&__range {
		&:nth-child(1),
		&:nth-child(2) {
			margin-bottom: 1rem;
		}
		&-wrap {
			width: 100%;
		}
		width: 100%;
		&-label,
		&-values {
			display: flex;
			justify-content: space-between;
			align-items: center;
			h4 {
				font-weight: 500;
				font-size: 1.2rem;
			}
			h3 {
				font-size: 17px;
				font-weight: 400;
			}
		}
		.input-wrapper {
			position: relative;
		}
		input {
			display: block;
			margin: 10px 0;
			accent-color: $primary;
			filter: drop-shadow(0 0 10px #000000d0);
			@media (max-width: $md) {
				margin: 0;
			}
		}
		&-label-amount {
			display: flex;
			align-items: baseline;
			input {
				font-size: 16px;
				display: block;
				margin-right: 10px;
				background-color: transparent;
				border: none;
				outline: none;
				text-align: right;
				border: 2px solid #319aa0;
				padding: 6px 10px 6px 0;
			}
		}
	}
	&__titles {
		text-align: center;
		width: 80%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		margin-bottom: 150px;

		h1 {
			p {
				font-size: 50px;
			}
			@media (min-width: $xxl) {
				font-size: 70px;
			}
		}

		div {
			width: 40%;
			height: 3px;
			background: linear-gradient(to left, #319ba116, #319ba1, #319ba116);
			border-radius: 100%;
		}

		p {
			font-size: 20px;
		}
	}
	&__calculates {
		height: 400px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 40px;

		&_col1 {
			padding: 0 0 5% 0;
			width: 55%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			gap: 4rem;
			position: relative;

			h1 {
				height: 30px;
				display: flex;
				flex-direction: column;
				position: relative;

				p {
					position: relative;
					z-index: 1;
				}

				span {
					height: 15px;
					background: $primary;
					position: absolute;
					top: 75%;
					left: -1%;
					z-index: 0;
				}

				div {
					position: absolute;
					bottom: -100%;
					right: 36%;
					height: 5px;
					background: $primary;
				}
			}

			&_slider1 {
				position: relative;
				width: 200%;
				padding: 0 0 0 1.4%;

				&-title {
					position: absolute;
					bottom: 140%;
					left: 0.2%;
					height: 25px;
					font-weight: 500;
					font-size: 1.2rem;
				}

				.row {
					height: 20px;
					display: flex;
					align-items: center;
					justify-content: flex-start;

					&__col {
						height: 100%;

						&_slider {
							position: absolute;
							top: -50%;
							left: -1%;
							width: 100%;

							.ant-slider-rail {
								background: #fff;
							}
						}
					}
				}

				&_inp {
					position: absolute;
					top: -150%;
					right: 46.5%;
					height: 30px;
					width: 150px;

					&-col {
						display: flex;
						justify-content: space-between;
						align-items: center;
						gap: 0.5rem;
						//&-input {
						//	background: none;
						//	border: none;
						//	color: $light;
						//	.ant-input-number {
						//	}
						//	.ant-input-number-handler-wrap {
						//		input {
						//			outline: none;
						//			&:focus {
						//				border: none;
						//				outline: none;
						//			}
						//		}
						//	}
						//	.ant-input-number-input-wrap {
						//		height: 30px;
						//		width: 150px;
						//		input {
						//			color: #fff;
						//			font-size: 20px;
						//			&:focus {
						//				border: none;
						//				outline: none;
						//			}
						//		}
						//	}
						//}
						p {
							//position: absolute;
							//top: -2%;
							//left: 500%;
							font-size: 20px;
						}

						span {
							//color: red;
							font-size: 18px;
						}
					}
				}

				.inp3 {
					position: absolute;
					top: -150%;
					right: 49%;
					height: 30px;
					width: 75px;

					&_mec {
						position: absolute;
						top: -2%;
						left: 230%;
						font-size: 20px;
					}
				}

				&-priceStart {
					position: absolute;
					bottom: -90%;
					left: 0.3%;
					font-size: 17px;
				}

				&-priceEnd {
					position: absolute;
					bottom: -90%;
					right: 50%;
					font-size: 17px;
				}
			}

			&-text {
				//position: absolute;
				bottom: 0;
				left: 0;
				z-index: 123;
				font-size: 20px;
				margin-top: -2rem;
			}
		}

		&_col2 {
			width: 35%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 2rem;
			text-align: center;
			position: relative;
			&-main {
				position: relative;
				z-index: 123;
				background-image: linear-gradient(to bottom, #000000bc, #000000da),
					url("../../media/ansQ1.png");
				background-size: cover;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;
				text-align: center;
				overflow: hidden;
				width: 100%;
				height: 100%;
				box-shadow: 2px 3px 35px 10px #000;
			}
			&-titles {
				color: $light;
				h2 {
					font-size: 20px;
					font-weight: 400;
					line-height: 30px;
					input {
						background: none;
						border: none;
						color: $light;
						font-size: 30px;
						text-align: center;
					}
					span {
						font-size: 40px;
						text-align: center;
						@media (max-width: $xl) {
							font-size: 40px;
						}
						@media (max-width: $md) {
							font-size: 35px;
						}
						@media (max-width: $xl) {
							font-size: 25px;
						}
					}
				}
			}
			&_div1 {
				border: 2px solid $primary;
				position: absolute;
				top: -1.5rem;
				left: -1.5rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 1;
				box-shadow: -5px 3px 35px rgba(0, 0, 0, 0.781);
			}
			&_div2 {
				background: linear-gradient(to bottom left, #178187, #319ba1, #48e2ea);
				position: absolute;
				right: -1.5rem;
				bottom: -1.5rem;
				width: 100%;
				height: 100%;
				object-fit: cover;
				z-index: 2;
				box-shadow: 3px 3px 35px rgba(0, 0, 0, 0.781);
			}
		}
	}
}
.ant-input-number-handler-wrap {
	//border-radius: none;
}
.fundsCalculate__calculates_col1_slider1_inp-col {
	outline: none;
	box-shadow: none;

	&:focus {
		border: none;
		outline: none;
		box-shadow: none;
	}

	&:active {
		border: none;
		outline: none;
		box-shadow: none;
	}
}
.ant-input-number {
	border-radius: 0;
	width: 130px;

	&:focus {
		border: none;
		outline: none;
	}

	&:active {
		border: none;
		outline: none;
	}

	input {
		&:focus {
			border: none;
			outline: none;
		}

		&:active {
			border: none;
			outline: none;
		}
	}
}
@media (max-width: $xl) {
	.fundsCalculate {
		// background: $bg;
		display: flex;
		flex-direction: column;
		gap: 2rem;

		&__titles {
			text-align: center;
			width: 80%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 1rem;
			margin-bottom: 5rem;

			h1 {
				font-size: 40px;
			}

			div {
				width: 40%;
				height: 3px;
				background: linear-gradient(to left, #319ba116, #319ba1, #319ba116);
				border-radius: 100%;
			}

			p {
				font-size: 20px;
			}
		}

		&__calculates {
			height: 400px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 20px;

			&_col1 {
				padding: 0 0 5% 0;
				width: 60%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				gap: 4rem;
				position: relative;

				h1 {
					height: 25px;
					display: flex;
					flex-direction: column;
					position: relative;

					p {
						position: relative;
						z-index: 1;
					}

					span {
						height: 15px;
						background: $primary;
						position: absolute;
						top: 75%;
						left: -1%;
						z-index: 0;
					}

					div {
						position: absolute;
						bottom: -100%;
						right: 36%;
						height: 5px;
						background: $primary;
					}
				}

				&_slider1 {
					position: relative;
					width: 200%;
					padding: 0 0 0 1.4%;

					&-title {
						position: absolute;
						bottom: 140%;
						left: 0.2%;
						height: 25px;
						font-weight: 500;
						font-size: 1.2rem;
					}

					.row {
						height: 20px;
						display: flex;
						align-items: center;
						justify-content: flex-start;

						&__col {
							height: 100%;

							&_slider {
								position: absolute;
								top: -50%;
								left: -1%;
								width: 100%;

								.ant-slider-rail {
									background: #fff;
								}
							}
						}
					}

					&_inp {
						position: absolute;
						top: -150%;
						right: 46.5%;
						height: 30px;
						width: 150px;

						&-col {
							&-input {
								background: none;
								border: none;
								color: $light;

								.ant-input-number {
								}

								.ant-input-number-handler-wrap {
									input {
										outline: none;

										&:focus {
											border: none;
											outline: none;
										}
									}
								}

								.ant-input-number-input-wrap {
									height: 30px;
									width: 150px;

									input {
										color: #fff;
										font-size: 20px;

										&:focus {
											border: none;
											outline: none;
										}
									}
								}
							}
						}
					}
					.inp3 {
						position: absolute;
						top: -150%;
						height: 30px;
						&_mec {
							position: absolute;
							top: -2%;
							left: 230%;
							font-size: 20px;
						}
					}
					&-priceStart {
						position: absolute;
						bottom: -90%;
						left: 0.3%;
						font-size: 17px;
					}

					&-priceEnd {
						position: absolute;
						bottom: -90%;
						right: 50%;
						font-size: 17px;
					}
				}

				&-text {
					//position: absolute;
					bottom: 0;
					left: 0;
					z-index: 123;
					font-size: 16px;
				}
			}

			&_col2 {
				width: 40%;
				height: 100%;
				background-image: linear-gradient(to bottom, #00000081, #000000ab),
					url("../../media/Group248.png");
				background-size: cover;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 1.5rem;
				text-align: center;
				// overflow: hidden;

				&-titles {
					h2 {
						font-size: 20px;
						font-weight: 400;

						input {
							background: none;
							border: none;
							color: $light;
							font-size: 30px;
							text-align: center;
						}
					}
				}
			}
		}
	}
}
@media (max-width: $md) {
	.fundsCalculate {
		padding-bottom: 60%;
		&__range {
			margin: 50px 0;
			&:nth-child(1),
			&:nth-child(2) {
				margin-bottom: 1rem;
			}
			&-wrap {
				width: 100%;
			}
			width: 100%;
			&-label,
			&-values {
				display: flex;
				justify-content: space-between;
				align-items: center;
				position: relative;
				margin: 10px 0;
				h4 {
					position: absolute;
					top: -100%;
					left: 0;
					font-weight: 500;
					font-size: 1.1rem;
				}
				h3 {
					font-size: 17px;
					font-weight: 400;
				}
			}
			.input-wrapper {
				position: relative;
			}
			input {
				display: block;
				margin: 10px 0;
				accent-color: $primary;
				filter: drop-shadow(0 0 10px #000000d0);
				@media (max-width: $md) {
					margin: 0;
				}
			}
			&-label-amount {
				display: flex;
				align-items: baseline;
				input {
					font-size: 16px;
					display: block;
					margin-right: 10px;
					background-color: transparent;
					border: none;
					outline: none;
					text-align: right;
					border: 2px solid #319aa0;
					padding: 6px 10px 6px 0;
				}
			}
		}
		&__titles {
			text-align: center;
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 1rem;
			margin-bottom: 3rem;

			h1 {
				font-size: 30px;
			}

			div {
				width: 40%;
				height: 3px;
				background: linear-gradient(to left, #319ba116, #319ba1, #319ba116);
				border-radius: 100%;
			}

			p {
				font-size: 15px;
			}
		}

		&__calculates {
			height: 550px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: column;
			gap: 70px;

			&_col1 {
				padding: 0 0 7% 0;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				gap: 2rem;
				position: relative;

				h1 {
					height: 20px;
					display: flex;
					flex-direction: column;
					position: relative;
					font-size: 20px;

					p {
						position: relative;
						z-index: 1;
					}

					span {
						height: 8px;
						background: $primary;
						position: absolute;
						top: 75%;
						left: -1%;
						z-index: 0;
					}

					div {
						position: absolute;
						bottom: -50%;
						right: 36%;
						height: 3px;
						background: $primary;
					}
				}

				&_slider1 {
					position: relative;
					width: 200%;
					padding: 0 0 0 1.4%;

					&-title {
						position: absolute;
						bottom: 140%;
						left: 0.2%;
						height: 20px;
						font-weight: 500;
						font-size: 1rem;
					}

					.row {
						height: 20px;
						display: flex;
						align-items: center;
						justify-content: flex-start;

						&__col {
							height: 100%;

							&_slider {
								position: absolute;
								top: -50%;
								left: -1%;
								width: 100%;

								.ant-input-number-input {
									font-size: 15px;
								}

								input {
								}

								.ant-slider-rail {
									background: #fff;
								}
							}
						}
					}

					&_inp {
						position: absolute;
						top: -150%;
						right: 46.5%;
						height: 30px;
						width: 150px;

						&-col {
							&-input {
								background: none;
								border: none;
								color: $light;

								.ant-input-number {
								}

								.ant-input-number-handler-wrap {
									input {
										outline: none;

										&:focus {
											border: none;
											outline: none;
										}
									}
								}

								.ant-input-number-input-wrap {
									height: 30px;
									width: 150px;

									input {
										color: #fff;
										font-size: 20px;

										&:focus {
											border: none;
											outline: none;
										}
									}
								}
							}

							//p {
							//	position: absolute;
							//	top: -2%;
							//	left: 500%;
							//	font-size: 20px;
							//}
						}
					}

					.inp3 {
						position: absolute;
						top: -150%;
						//right: 46%;
						height: 30px;
						width: 150px;

						&_mec {
							position: absolute;
							top: -2%;
							left: 230%;
							font-size: 20px;
						}
					}

					&-priceStart {
						position: absolute;
						bottom: -90%;
						left: 0.3%;
						font-size: 17px;
					}

					&-priceEnd {
						position: absolute;
						bottom: -90%;
						right: 50%;
						font-size: 17px;
					}
				}

				&-text {
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 123;
					font-size: 15px;
					text-align: center;
					display: none;
				}
			}

			&_col2 {
				margin-top: -30%;
				width: 100%;
				padding: 20% 0;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				background-image: none;
				&-main {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 30vh;
					background: #000;
					z-index: 12;
					box-shadow: 2px 3px 10px #000;
					padding: 20px 0;
					gap: 0.5rem;
					overflow-x: hidden;
				}
				&_div1 {
					height: 30vh;
				}
				&_div2 {
					height: 30vh;
					bottom: -8.5rem;
				}
				&-titles {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center !important;
					width: 100%;
					h2 {
						font-size: 15px;
						font-weight: 400;
						width: 100%;
						text-align: center;
						line-height: 20px;
						input {
							width: 100%;
							background: none;
							border: none;
							color: $light;
							font-size: 25px;
							text-align: center;
						}
					}
				}
			}
		}
	}
}
@media (max-width: $sm) {
	// .fundsCalculate {
	// 	padding-bottom: 30%;
	// 	&__titles {
	// 		text-align: center;
	// 		width: 100%;
	// 		margin: 0 auto;
	// 		display: flex;
	// 		flex-direction: column;
	// 		align-items: center;
	// 		justify-content: center;
	// 		gap: 1rem;
	// 		margin-bottom: 3rem;

	// 		h1 {
	// 			p {
	// 				font-size: 30px;
	// 			}
	// 		}

	// 		div {
	// 			width: 40%;
	// 			height: 3px;
	// 			background: linear-gradient(to left, #319ba116, #319ba1, #319ba116);
	// 			border-radius: 100%;
	// 		}

	// 		p {
	// 			font-size: 15px;
	// 		}
	// 	}

	// 	&__calculates {
	// 		height: 550px;
	// 		display: flex;
	// 		justify-content: space-between;
	// 		align-items: center;
	// 		flex-direction: column;
	// 		gap: 70px;

	// 		&_col1 {
	// 			padding: 0 0 7% 0;
	// 			width: 100%;
	// 			display: flex;
	// 			flex-direction: column;
	// 			align-items: flex-start;
	// 			justify-content: center;
	// 			gap: 2rem;
	// 			position: relative;

	// 			h1 {
	// 				height: 20px;
	// 				display: flex;
	// 				flex-direction: column;
	// 				position: relative;
	// 				p {
	// 					font-size: 20px;
	// 					position: relative;
	// 					z-index: 1;
	// 				}

	// 				span {
	// 					height: 8px;
	// 					background: $primary;
	// 					position: absolute;
	// 					top: 75%;
	// 					left: -1%;
	// 					z-index: 0;
	// 				}

	// 				div {
	// 					position: absolute;
	// 					bottom: -50%;
	// 					right: 36%;
	// 					height: 3px;
	// 					background: $primary;
	// 				}
	// 			}

	// 			&_slider1 {
	// 				position: relative;
	// 				width: 200%;
	// 				padding: 0 0 0 1.4%;

	// 				&-title {
	// 					position: absolute;
	// 					bottom: 140%;
	// 					left: 0.2%;
	// 					height: 20px;
	// 					font-weight: 500;
	// 					font-size: 1rem;
	// 				}

	// 				.row {
	// 					height: 20px;
	// 					display: flex;
	// 					align-items: center;
	// 					justify-content: flex-start;

	// 					&__col {
	// 						height: 100%;

	// 						&_slider {
	// 							position: absolute;
	// 							top: -50%;
	// 							left: -1%;
	// 							width: 100%;

	// 							.ant-input-number-input {
	// 								font-size: 15px;
	// 							}

	// 							input {
	// 							}

	// 							.ant-slider-rail {
	// 								background: #fff;
	// 							}
	// 						}
	// 					}
	// 				}

	// 				&_inp {
	// 					position: absolute;
	// 					top: -150%;
	// 					right: 46.5%;
	// 					height: 30px;
	// 					width: 150px;

	// 					&-col {
	// 						&-input {
	// 							background: none;
	// 							border: none;
	// 							color: $light;

	// 							.ant-input-number {
	// 							}

	// 							.ant-input-number-handler-wrap {
	// 								input {
	// 									outline: none;

	// 									&:focus {
	// 										border: none;
	// 										outline: none;
	// 									}
	// 								}
	// 							}

	// 							.ant-input-number-input-wrap {
	// 								height: 30px;
	// 								width: 150px;

	// 								input {
	// 									color: #fff;
	// 									font-size: 20px;

	// 									&:focus {
	// 										border: none;
	// 										outline: none;
	// 									}
	// 								}
	// 							}
	// 						}

	// 						//p {
	// 						//	position: absolute;
	// 						//	top: -2%;
	// 						//	left: 500%;
	// 						//	font-size: 20px;
	// 						//}
	// 					}
	// 				}

	// 				.inp3 {
	// 					position: absolute;
	// 					top: -150%;
	// 					//right: 46%;
	// 					height: 30px;
	// 					width: 150px;

	// 					&_mec {
	// 						position: absolute;
	// 						top: -2%;
	// 						left: 230%;
	// 						font-size: 20px;
	// 					}
	// 				}

	// 				&-priceStart {
	// 					position: absolute;
	// 					bottom: -90%;
	// 					left: 0.3%;
	// 					font-size: 17px;
	// 				}

	// 				&-priceEnd {
	// 					position: absolute;
	// 					bottom: -90%;
	// 					right: 50%;
	// 					font-size: 17px;
	// 				}
	// 			}

	// 			&-text {
	// 				position: absolute;
	// 				bottom: -15%;
	// 				left: 0;
	// 				z-index: 123;
	// 				font-size: 15px;
	// 				text-align: center;
	// 			}
	// 		}

	// 		&_col2 {
	// 			margin-top: 5%;
	// 			width: 100%;
	// 			padding: 20% 0;
	// 			flex-direction: row;
	// 			align-items: center;
	// 			justify-content: center;
	// 			background-image: none;
	// 			&-main {
	// 				position: absolute;
	// 				top: 0;
	// 				left: 0;
	// 				width: 100%;
	// 				height: 100%;
	// 				background: #000;
	// 				z-index: 12;
	// 				box-shadow: 2px 3px 10px #000;
	// 			}
	// 			&-titles {
	// 				display: flex;
	// 				flex-direction: row;
	// 				align-items: center;
	// 				justify-content: center !important;
	// 				width: 100%;

	// 				h2 {
	// 					font-size: 17px;
	// 					font-weight: 400;
	// 					width: 100%;
	// 					text-align: center;

	// 					input {
	// 						width: 100%;
	// 						background: none;
	// 						border: none;
	// 						color: $light;
	// 						font-size: 15px;
	// 						text-align: center;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}
