@import "../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.company {
	padding: 150px 0;
	@media (min-width: 1650px) {
		padding: 250px 0 150px 0;
	}

	&__title {
		position: relative;
		font-weight: 700;
		font-size: 50px;
		line-height: 70px;
		padding-bottom: 10px;
		margin-bottom: 10px;
		max-width: fit-content;
		font-family: $font_titles;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			height: 0.1px;
			display: inline-block;
			width: 100%;
			background-image: linear-gradient(
				10deg,
				rgba(171, 174, 183, 0) 0%,
				#319ba1 51.56%,
				rgba(171, 174, 183, 0) 100%
			);
		}
	}

	&__par {
		font-family: $font_texts;
		font-weight: 200;
		font-size: 25px;
		line-height: 40px;
		margin-bottom: 3rem;

		@media (max-width: $lg) {
			font-size: 20px;

			&-highlight {
				font-size: 22px;
			}
		}

		@media (max-width: $sm) {
			font-size: 16px;
			line-height: 35px;

			&-highlight {
				font-size: 20px;
			}
		}
	}

	.par__title {
		font-weight: 700;
	}

	.card-wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		flex-wrap: wrap;
		margin-top: 150px;
		gap: 1rem;

		@media (max-width: $xl) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: $md) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}
