@import "../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.funds {
	padding: 150px 0;
	overflow-x: hidden;
	@media (min-width: 1650px) {
		padding: 250px 0 150px 0;
	}
	&__imgText {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 130px;
		padding: 5% 0;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			box-shadow: 5px 3px 35px rgba(0, 0, 0, 0.781);
		}
		&_img {
			width: 450px;
			height: 450px;
			position: relative;
			z-index: 123;
		}
		&_div1 {
			border: 2px solid $primary;
			position: absolute;
			top: -1.5rem;
			left: -1.5rem;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: -1;
			box-shadow: -5px 3px 35px rgba(0, 0, 0, 0.781);
		}
		&_div2 {
			background: linear-gradient(to bottom left, #178187, #319ba1, #48e2ea);
			position: absolute;
			right: -1.5rem;
			bottom: -1.5rem;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: -1;
			box-shadow: 3px 3px 35px rgba(0, 0, 0, 0.781);
		}
	}
	&__par {
		width: 50%;
		font-family: $font_texts;
		font-weight: 200;
		font-size: 25px;
		line-height: 33px;
		@media (max-width: $lg) {
			width: 100%;
			text-align: center;
			font-size: 20px;
			&-highlight {
				font-size: 22px;
			}
		}

		@media (max-width: $md) {
			overflow-x: hidden;
			font-size: 16px;
			line-height: 25px;
			width: 100%;
			margin-top: 30px;
			text-align: center;
			&-highlight {
				font-size: 20px;
			}
		}
	}

	.card-wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		flex-wrap: wrap;
		margin-top: 150px;
		gap: 1rem;

		@media (max-width: $xl) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: $md) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}
