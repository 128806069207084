@import "../../scss/varuables.scss";

.requisites {
	position: relative;
	padding: 150px 0;
	overflow-x: hidden;
	@media (min-width: 1650px) {
		padding: 250px 0 150px 0;
	}
	.container {
		overflow-x: hidden;
	}
	.side-img {
		position: absolute;
		top: 10%;
		right: 5%;
		width: 40%;
		@media (min-width: 1650px) {
			top: 20%;
		}
		&_div1 {
			border: 2px solid $primary;
			position: absolute;
			top: -1.5rem;
			left: -1.5rem;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 0;
			box-shadow: -5px 3px 35px rgba(0, 0, 0, 0.781);
		}
		&_div2 {
			background: linear-gradient(to bottom left, #178187, #319ba1, #48e2ea);
			position: absolute;
			right: -1.5rem;
			bottom: -1.5rem;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 0;
			box-shadow: 3px 3px 35px rgba(0, 0, 0, 0.781);
		}
		.img {
			position: relative;
			display: inline-block;
			width: 100%;
			height: 100%;
			z-index: 123;
			box-shadow: 5px 3px 35px rgba(0, 0, 0, 0.781);
		}
		@media (max-width: $lg) {
			position: relative;
			width: 100%;
			padding: 0 10%;
			margin: 0 auto;
			margin-bottom: 4rem;
		}
		@media (max-width: $md) {
			top: 5% !important;
			right: 0%;
			width: 100%;
			&_div1 {
				top: -1.2rem;
				left: 1rem;
				width: 80%;
			}
			&_div2 {
				right: 1rem;
				bottom: -1.2rem;
				width: 80%;
			}
		}
	}
	&__content {
		max-width: 55%;
		overflow-x: hidden;
		@media (max-width: $lg) {
			max-width: 100%;
		}
	}
	&__section {
		overflow-x: hidden;
		margin-top: 3rem;
		@media (max-width: $lg) {
			margin-top: 2rem;
		}
		@media (max-width: $sm) {
			margin-top: 1rem;
		}
	}
	&__text {
		overflow-x: hidden;
		font-family: $font-texts;
		font-weight: 400;
		font-size: 25px;
		line-height: 35px;

		@media (max-width: $lg) {
			font-size: 20px;

			&-highlight {
				font-size: 22px;
			}
		}

		@media (max-width: $sm) {
			font-size: 16px;
			line-height: 35px;

			&-highlight {
				font-size: 20px;
			}
		}
	}
	@media (max-width: $md) {
		margin: 130px 0;
	}
}
