@import "../../scss/varuables.scss";

.clients {
	padding: 150px 0;
	font-family: Inter;
	overflow-x: hidden;
	@media (min-width: 1650px) {
		padding: 250px 0 150px 0;
	}
	section {
		position: relative;
		padding-bottom: 20px;
		margin-bottom: 20px;
		&::after {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 280px;
			height: 1px;
			background: linear-gradient(
				90deg,
				rgba(171, 174, 183, 0) 0%,
				#319ba1 51.56%,
				rgba(171, 174, 183, 0) 100%
			);
		}
	}
	ol {
		font-size: 25px;
		@media (max-width: $xl) {
			font-size: 18px;
		}
		@media (max-width: $sm) {
			font-size: 16px;
		}
	}
	li {
		list-style-type: decimal;
		margin-left: 29px;
		@media (max-width: $xl) {
			margin-left: 20px;
		}
		@media (max-width: $sm) {
			margin-left: 18px;
		}
		&.colored {
			color: #319ba1;
		}
	}
	a {
		color: #319ba1;
		font-weight: 400;
		font-size: 25px;
		line-height: 40px;
		@media (max-width: $xl) {
			font-size: 18px;
		}
		@media (max-width: $sm) {
			font-size: 16px;
		}
	}
	p {
		font-weight: 400;
		font-size: 25px;
		line-height: 40px;
		@media (max-width: $xl) {
			font-size: 18px;
			line-height: 35px;
		}
		@media (max-width: $sm) {
			font-size: 16px;
			line-height: 30px;
		}
	}
	&__title {
		font-weight: 600;
		font-size: 30px;
		line-height: 40px;
		margin-bottom: 10px;
		margin-top: 20px;
		@media (max-width: $md) {
			font-size: 26px;
		}
		@media (max-width: $sm) {
			line-height: 32px;
		}
	}
	&__hero {
		display: flex;
		justify-content: space-between;
		align-items: start;
		gap: 2rem;
		.content {
			@media (max-width: $lg) {
				order: 1;
			}
		}
		@media (max-width: $lg) {
			flex-direction: column;
		}
	}
	.image {
		text-align: center;
		min-width: max-content;
		.img_wrap {
			max-width: 325px;
			min-height: 300px;
			aspect-ratio: 1 / 1;
			border-radius: 50%;
			overflow: hidden;
			&:hover {
				img {
					transform: scale(1.02);
				}
			}
		}
		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
			&:hover {
				// transform: scale(1.08);
			}
		}
		&__content {
			display: flex;
			flex-direction: column;
			gap: 5px;
			margin-top: 5px;
			h3 {
				font-weight: 700;
				font-size: 25px;
				line-height: 40px;
				@media (max-width: $xl) {
					font-size: 18px;
				}
			}
			p {
				font-weight: 400;
				font-size: 25px;
				line-height: 40px;
				margin-bottom: 5px;
				color: #319ba1;
			}
		}
		@media (max-width: $lg) {
			margin-right: auto;
			margin-left: auto;
		}
	}
	.info {
		&__title {
			font-weight: 600;
			font-size: 30px;
			line-height: 40px;
			span {
				font-weight: 600;
				font-size: 30px;
				line-height: 40px;
				color: #319ba1;
			}
			@media (max-width: $sm) {
				font-size: 26px;
				span {
					font-size: 26px;
				}
			}
		}
		h4 {
			font-weight: 700;
			font-size: 25px;
			line-height: 35px;
			margin-top: 15px;
			@media (max-width: $xl) {
				font-size: 18px;
			}
		}
		p {
			margin-top: 2rem;
		}
	}
}
