@import "./varuables.scss";
* {
	padding: 0;
	margin: 0;
	color: inherit;
	text-decoration: none;
	list-style: none;
	box-sizing: border-box;
}
a:hover {
	// color: $primary;
}
body {
	overflow-x: hidden;
	margin: 0 auto;
	// background: $bg;
}
.container {
	width: 100%;
	padding: 0 10%;
	margin: 0 auto;
}
.mobileNav {
	display: none;
}
// h1 {
// 	text-shadow: 2px 5px 10px rgba(0, 0, 0, 0.747);
// }
@media (max-width: $xl) {
	.mobileNav {
		display: block;
	}
}
@media (max-width: $sm) {
	.container {
		padding: 0 5%;
	}
}
