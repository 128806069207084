@import "../../scss/varuables.scss";

.aboutLeasingDiv {
	background: var(--background);
	position: relative;
	padding: 5% 0;
	.aboutLeasing {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&__col1 {
			width: 60%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;
			gap: 2rem;
			&_titles {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 1rem;
				position: relative;
				div {
					height: 80px;
					width: 2px;
					border-radius: 50%;
					background: linear-gradient(to bottom, #319ba124, #319ba1, #319ba124);
				}
				h1 {
					height: 80px;
					font-size: 50px;
					font-family: $font_titles;
					position: relative;
					p {
						font-size: 50px;
						position: relative;
						z-index: 1;
					}
					span {
						width: 100px;
						height: 15px;
						background: $primary;
						position: absolute;
						top: 50%;
						left: 0;
						z-index: 0;
					}

					@media (min-width: $xxl) {
						p {
							font-size: 70px;
						}

						span {
							top: 75%;
						}
					}
				}
			}
			&_texts {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-between;
				gap: 1rem;
				font-weight: 400;
				font-family: $font_texts;
				font-size: 17px;
				span {
					font-weight: 700;
				}
			}
		}
		&__col2 {
			width: 37%;
			height: 100%;
			&_img1 {
				width: 50%;
				position: absolute;
				top: 23%;
				right: -13%;
				z-index: 3;
				box-shadow: 2px 10px 30px 7px rgba(0, 0, 0, 0.687);
			}
			&_img2 {
				width: 40%;
				position: absolute;
				top: 20%;
				right: -10%;
				z-index: 2;
				transform: rotate(5deg);
				box-shadow: 2px 7px 30px 7px rgba(0, 0, 0, 0.385);
			}
			&_img3 {
				width: 40%;
				position: absolute;
				bottom: 16%;
				right: -2%;
				z-index: 1;
				transform: rotate(-5deg);
				box-shadow: 2px 7px 30px 7px rgba(0, 0, 0, 0.169);
			}
			&_img4 {
				width: 40%;
				position: absolute;
				bottom: 16%;
				right: -2%;
				z-index: 1;
				transform: rotate(-5deg);
				box-shadow: 2px 7px 30px 7px rgba(0, 0, 0, 0.186);
			}

			@media (min-width: $xxl) {
				height: 48rem;
			}
		}
	}
}
@media (max-width: $xl) {
	.aboutLeasingDiv {
		// background: $bg;
		position: relative;
		padding: 5% 0 0 0;
		.aboutLeasing {
			&__col1 {
				width: 65%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-between;
				gap: 1rem;
				&_titles {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 1rem;
					position: relative;
					div {
						height: 30px;
						width: 2px;
						border-radius: 50%;
						background: linear-gradient(
							to bottom,
							#319ba124,
							#319ba1,
							#319ba124
						);
					}
					h1 {
						height: 40px;
						font-size: 35px;
						font-family: $font_titles;
						position: relative;
						p {
							font-size: 40px;
							position: relative;
							z-index: 1;
						}
						span {
							width: 100px;
							height: 15px;
							background: $primary;
							position: absolute;
							top: 70%;
							left: 0;
							z-index: 0;
						}
					}
				}
				&_texts {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: space-between;
					gap: 1rem;
					font-weight: 400;
					font-family: $font_texts;
					font-size: 15px;
					span {
						font-weight: 700;
					}
				}
			}
			&__col2 {
				width: 37%;
				height: 100%;
				&_img1 {
					width: 50%;
					position: absolute;
					top: 40%;
					right: -13%;
					z-index: 3;
				}
				&_img2 {
					width: 40%;
					position: absolute;
					top: 30%;
					right: -10%;
					z-index: 2;
					transform: rotate(5deg);
				}
				&_img3 {
					width: 40%;
					position: absolute;
					bottom: 16%;
					right: -2%;
					z-index: 1;
					transform: rotate(-5deg);
				}
				&_img4 {
					width: 40%;
					position: absolute;
					bottom: 16%;
					right: -2%;
					z-index: 1;
					transform: rotate(-5deg);
				}
			}
		}
	}
}
@media (max-width: $md) {
	.aboutLeasingDiv {
		// background: $bg;
		position: relative;
		padding: 5% 0 0 0;
		.aboutLeasing {
			display: flex;
			flex-direction: column;
			&__col1 {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-between;
				gap: 1rem;
				position: relative;
				z-index: 12;
				&_titles {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 0.5rem;
					position: relative;
					div {
						height: 30px;
						width: 2px;
						border-radius: 50%;
						background: linear-gradient(
							to bottom,
							#319ba124,
							#319ba1,
							#319ba124
						);
					}
					h1 {
						height: 40px;
						font-size: 30px;
						font-family: $font_titles;
						position: relative;
						p {
							font-size: 30px;
							position: relative;
							z-index: 1;
						}
						span {
							width: 100px;
							height: 10px;
							background: $primary;
							position: absolute;
							top: 55%;
							left: 0;
							z-index: 0;
						}
					}
				}
				&_texts {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: space-between;
					gap: 1rem;
					font-weight: 400;
					font-family: $font_texts;
					font-size: 15px;
					// text-align: center;
					span {
						font-weight: 700;
					}
				}
			}
			&__col2 {
				opacity: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				bottom: 0;
				right: 0;
				&_img1 {
					width: 50%;
					position: absolute;
					top: 40%;
					right: -13%;
					z-index: 3;
				}
				&_img2 {
					width: 40%;
					position: absolute;
					top: 30%;
					right: -10%;
					z-index: 2;
					transform: rotate(5deg);
				}
				&_img3 {
					width: 40%;
					position: absolute;
					bottom: 16%;
					right: -2%;
					z-index: 1;
					transform: rotate(-5deg);
				}
				&_img4 {
					width: 40%;
					position: absolute;
					bottom: 16%;
					right: -2%;
					z-index: 1;
					transform: rotate(-5deg);
				}
			}
		}
	}
}
