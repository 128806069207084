.switch {
	margin-right: 5px;
	width: 35px;
	height: 35px;
	position: relative;
	span {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.5s;
		cursor: pointer;
	}
	&__sun {
		padding: 5px;

		width: 100%;
		height: 100%;
	}
	&__moon {
		padding: 5px;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: 0s !important;
		font-size: 2rem;
	}
	input {
		display: none;
	}
	input:checked ~ .switch__sun {
		transform: perspective(100px) rotateY(180deg);

		opacity: 0;
	}
	input:checked ~ .switch__moon {
		transform: perspective(100px) rotateY(-180deg);
		opacity: 1;
	}
}

// .input {
//    display: none;
// }

// .input:checked ~ .label > .button {
//    transform: translateX(18px) translateY(-2px) rotate(225deg);
//    &::after {
//       content: url(./icons/moon-solid.svg);
//    }
// }

// .label {
//    display: block;
//    width: 50px;
//    height: 25px;
//    background-color: #0000003e;
//    border-radius: 20px;
//    padding: 10px 5px;
//    display: flex;
//    align-items: center;
//    cursor: pointer;
// }

// .button {
//    display: inline-block;
//    height: 25px;
//    width: 25px;
//    aspect-ratio: 1 / 1;
//    border-radius: 10px;
//    color: #f8f8ff;
//    transform: translateY(2px) rotate(0deg);
//    transition: transform 1s;
//    &::after {
//       content: url(./icons/sun-solid.svg);
//    }
// }

// .root {
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	width: 120px;
// 	height: 50px;
// 	transform: translate(-50%, -50%);
// 	input {
// 		display: none;
// 	}
// 	.slider {
// 		position: absolute;
// 		top: 0;
// 		right: 0;
// 		bottom: 0;
// 		left: 0;
// 		z-index: 1;
// 		overflow: hidden;
// 		background-color: #e74a42;
// 		border-radius: 50px;
// 		cursor: pointer;
// 		transition: all 1.4s;
// 		&:before,
// 		&:after {
// 			content: "";
// 			position: absolute;
// 			bottom: 5px;
// 			left: 5px;
// 			width: 40px;
// 			height: 40px;
// 			background-color: #ffffff;
// 			border-radius: 30px;
// 		}
// 		&:before {
// 			transition: 0.4s;
// 		}
// 		&:after {
// 			transition: 0.5s;
// 		}
// 	}
// 	.wave {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		width: 120px;
// 		height: 50px;
// 		border-radius: 40px;
// 		transition: all 1.4s;
// 		&:after {
// 			content: "";
// 			position: absolute;
// 			top: 3px;
// 			left: 20%;
// 			width: 60px;
// 			height: 3px;
// 			background: #ffffff;
// 			border-radius: 100%;
// 			opacity: 0.4;
// 		}
// 		&:before {
// 			content: "";
// 			position: absolute;
// 			top: 10px;
// 			left: 30%;
// 			width: 35px;
// 			height: 2px;
// 			background: #ffffff;
// 			border-radius: 100%;
// 			opacity: 0.3;
// 		}
// 	}
// 	input:checked + .slider {
// 		background-color: transparent;
// 		&:before,
// 		&:after {
// 			transform: translateX(70px);
// 		}
// 	}
// 	input:checked ~ .wave {
// 		display: block;
// 		background-color: #3398d9;
// 	}
// }
