@import "../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.map {
	// background: $bg;
	padding: 9% 0 7% 0;
	h1 {
		font-size: 50px;
		font-family: $font_titles;
		margin: 2% 0 2% 0.1%;
		position: relative;
		z-index: 1;
		p {
			position: relative;
			z-index: 1;
		}
		span {
			width: 90px;
			height: 15px;
			background: $primary;
			position: absolute;
			top: 60%;
			left: -0.5%;
			z-index: 0;
		}
	}
	.mapDiv {
		z-index: 12;
	}
	&__div {
		position: relative;
		z-index: 12;
		width: 100%;
		height: 400px;
	}
}

@media (min-width: $xxl) {
	.map {
		h2 {
			font-size: 80px;
		}
		iframe {
			height: 600px;
		}
	}
}
@media (max-width: $xxl) {
	.map {
		iframe {
			height: 500px;
		}
	}
}
@media (max-width: $xl) {
	.map {
		h1 {
			font-size: 40px;
		}
		iframe {
			height: 400px;
		}
	}
}
@media (max-width: $md) {
	.map {
		h1 {
			font-size: 25px;
			p {
				font-size: 25px;
			}
			span {
				left: -0.1rem;
			}
		}
		iframe {
			height: 350px;
		}
	}
}
@media (max-width: $sm) {
	.map {
		h1 {
			font-size: 25px;
			margin-left: 1px;
			p {
				font-size: 30px;
			}
			span {
				height: 8px;
				width: 50%;
				left: -0.1rem;
			}
		}
		iframe {
			height: 300px;
		}
	}
}
@media (max-width: $sm) {
	.map {
		h1 {
			font-size: 30px;
			span {
				width: 82px;
				left: -0.1rem;
			}
		}
		iframe {
			height: 300px;
		}
	}
}
