@import "../../scss/varuables.scss";

.programs {
	padding: 150px 0;
	overflow-x: hidden;
	@media (min-width: 1650px) {
		padding: 250px 0 150px 0;
	}
	&__mainInfo {
		margin-bottom: 150px;
	}

	&__text {
		font-family: $font_texts;
		font-weight: 300;
		font-size: 22px;
		line-height: 40px;
		margin-top: 36px;

		&-highlight {
			font-size: 26px;
			font-weight: 600;
		}

		@media (max-width: $lg) {
			font-size: 20px;

			&-highlight {
				font-size: 22px;
			}
		}

		@media (max-width: $sm) {
			font-size: 16px;
			line-height: 35px;

			&-highlight {
				font-size: 20px;
			}
		}
	}
}
