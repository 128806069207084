@import "../../scss/varuables.scss";
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.overlay {
	.modalContainer {
		overflow: hidden;
		border-radius: $br_r_0;
		max-width: 1000px;
		width: 95%;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -350%);
		display: flex;
		flex-direction: column;
		background: var(--background);
		box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.75);
		padding: 2% 2% 2% 2%;
		transition: 1s all;
		z-index: 10000000;
	}
	.modalBg {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 100;
		display: none;
	}
	&.active {
		background: red;
		.modalContainer {
			transform: translate(-50%, -50%);
		}
		.modalBg {
			display: block;
		}
	}
	.close {
		cursor: pointer;
		position: absolute;
		top: 7%;
		right: 4%;
		z-index: 12345;
		svg {
			color: var(--color);
			font-size: 30px;
		}
	}
}

.modalContainer.animate {
	top: 50%;
}
@media (min-width: $xxl) {
	.overlay {
		.modalContainer {
			overflow: hidden;
			border-radius: $br_r_10;
			max-width: 1500px;
			width: 100%;
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -350%);
			display: flex;
			flex-direction: column;
			background: $bg;
			box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.75);
			padding: 2% 2% 2% 2%;
			transition: 1s all;
			z-index: 101;
		}
		.modalBg {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
			z-index: 100;
			display: none;
		}
		&.active {
			background-color: red;
			.modalContainer {
				transform: translate(-50%, -50%);
			}
			.modalBg {
				display: block;
			}
		}
		.close {
			position: absolute;
			top: 7%;
			right: 4%;
			z-index: 12345;
			svg {
				color: #fff;
				font-size: 50px;
			}
		}
	}
}
