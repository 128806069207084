@import "../../scss/varuables.scss";
.leasingImagesDiv {
	width: 100%;
	position: relative;
	padding-top: 10%;
	background: var(--background);
}
.leasingImages {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
	padding: 5% 0 0 0;
	position: relative;
	z-index: 1;
	&__imgDiv {
		width: 20%;
		height: 500px;
		overflow: hidden;
		position: relative;
		cursor: pointer;
		box-shadow: 3px 7px 30px 5px rgba(0, 0, 0, 0.658);
		&:hover {
			p {
				color: $light;
				position: absolute;
				pointer-events: none;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 4rem 0 3rem 0;
				background-image: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0),
					rgba(0, 0, 0, 0.9)
				);

				@media (max-width: 1145px) {
					font-size: 18px;
					background-image: linear-gradient(
						to bottom,
						rgba(0, 0, 0, 0),
						rgba(0, 0, 0, 0.5)
					);
				}
			}
		}
		&:nth-child(odd) {
			margin-bottom: 10%;
			img {
			}
		}
		&:nth-child(even) {
			img {
				margin-bottom: 5%;
			}
		}
		img {
			position: relative;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
		p {
			text-align: center;
			position: absolute;
			left: 0;
			bottom: -25vh;
			width: 100%;
			transition: 0.4s;
			font-size: 1.5rem;
		}
		@media (min-width: $xxl) {
			height: 748px;
		}

		@media (min-width: 1696px) {
			height: 948px;
		}

		@media (max-width: 885px) {
			p {
				font-size: 16px;
				// padding: 4rem 0 3rem 0;
				bottom: -25%;
				background-image: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0),
					rgba(0, 0, 0, 0.5)
				);
			}
		}
	}
}
@media (min-width: $xxl) {
	.leasingImages {
		height: 800px;
		padding: 35% 0 24% 0;
		&__imgDiv {
			height: 800px;
			img {
				height: 800px;
				width: 100%;
			}
		}
	}
}
@media (max-width: $xl) {
	.leasingImages {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: auto;
		padding: 10% 0 7% 0;
		// background: $bg;
		position: relative;
		z-index: 1;
		&__imgDiv {
			width: 20%;
			height: 400px;
			&:nth-child(odd) {
				padding-bottom: 5%;

				@media (max-width: 1145px) {
					padding-bottom: 0px;
				}
			}
			&:nth-child(even) {
				padding-top: 5%;
			}
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
	}
}
@media (max-width: $md) {
	.leasingImagesDiv {
		width: 100%;
		height: 550px;
		background: var(--background);
		padding-top: 20%;
	}
	.leasingImages {
		display: none;
	}
}
@media (max-width: $sm) {
	.leasingImagesDiv {
		width: 100%;
		height: 500px;
		background: var(--background);
	}
}

@media (max-width: $xs) {
	.leasingImages {
		max-width: 230px;
		margin-right: auto;
		margin-left: auto;
		// &__imgDiv {
		// 	width: 20%;
		// 	height: 300px;
		// 	&:nth-child(odd) {
		// 		width: 100%;
		// 	}
		// 	&:nth-child(even) {
		// 		width: 100%;
		// 	}
		// 	img {
		// 		height: 100%;
		// 		width: 100%;
		// 		object-fit: cover;
		// 	}
		// }
	}
}
