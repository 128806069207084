@import "../../scss/varuables.scss";

.leasing {
	padding: 150px 0;
	@media (min-width: 1650px) {
		padding: 250px 0 150px 0;
	}
	.leasing__hero-img {
		position: absolute;
		top: 15%;
		right: 5%;
		width: 35%;
		img {
			position: relative;
			display: inline-block;
			width: 100%;
			height: calc(100% - 2rem);
			z-index: 10;
			box-shadow: 5px 3px 35px rgba(0, 0, 0, 0.781);
		}
		&_div1 {
			border: 2px solid $primary;
			position: absolute;
			top: -1.5rem;
			left: -1.5rem;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 1;
			box-shadow: -5px 3px 35px rgba(0, 0, 0, 0.781);
		}
		&_div2 {
			background: linear-gradient(to bottom left, #178187, #319ba1, #48e2ea);
			position: absolute;
			right: -1.5rem;
			bottom: -1.5rem;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 1;
			box-shadow: 3px 3px 35px rgba(0, 0, 0, 0.781);
		}
		@media (max-width: $lg) {
			position: relative;
			width: 100%;
			padding: 0 10%;
			margin: 0 auto;
			margin-bottom: 4rem;
			img {
				width: 100%;
			}
		}
		@media (max-width: $md) {
			right: 0%;
			&_div1 {
				top: -1rem;
				left: 1rem;
				width: 80%;
			}
			&_div2 {
				right: 1rem;
				bottom: -1rem;
				width: 80%;
			}
		}
	}
	&__hero {
		position: relative;
		// @media (min-width: 1650px) {
		// 	margin-top: 250px;
		// }
		@media (max-width: 776px) {
			margin-top: 200px;
		}
		&-wrapper {
			max-width: 60%;
			@media (max-width: $lg) {
				max-width: 100%;
			}
		}

		&-title {
			font-family: $font-titles;
			font-weight: 700;
			font-size: 50px;
			line-height: 70px;
		}

		&-text {
			font-family: $font-texts;
			font-weight: 400;
			font-size: 20px;
			line-height: 170%;
			margin: 10px 0 20px 0;

			@media (max-width: $lg) {
				font-size: 20px;

				&-highlight {
					font-size: 22px;
				}
			}

			@media (max-width: $sm) {
				font-size: 16px;
				line-height: 35px;

				&-highlight {
					font-size: 20px;
				}
			}
		}

		&-btns {
			display: flex;
			gap: 3rem;

			@media (max-width: $sm) {
				flex-direction: column;
				gap: 1rem;
			}
		}
		&-btn {
			button {
				display: inline-block;
				padding: 0 25px;
				font-family: $font-texts;
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 35px;
				height: 4.5rem;
				min-width: 15rem;
			}
		}
	}
	&__cards {
		margin: 150px 0;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1rem;
		flex-wrap: nowrap;
		// overflow-x: hidden;
		@media (min-width: 1652px) {
			margin: 300px 0 150px 0;
		}
		@media (max-width: 1352px) {
			grid-template-columns: repeat(3, 1fr);
			> div:first-child {
				order: 1;
				grid-column: 1 / 4;
				grid-row: 2 / 3;
				@media (max-width: $lg) {
					grid-column: 1 / 2;
				}
			}

			> div:last-child {
				grid-column: unset;
			}
		}

		@media (max-width: $lg) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (max-width: $sm) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
	.explain {
		&__title {
			font-family: $font-titles;
			font-weight: 700;
			font-size: 50px;
			line-height: 70px;
		}

		&__wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding-top: 60px;

			@media (max-width: $md) {
				row-gap: 2rem;
			}
		}

		&__item {
			position: relative;
			width: calc(25% - 2px);
			text-align: center;
			padding: 1rem 20px;
			img {
				display: inline-block;
				height: 50px;
				width: 50px;
				object-fit: contain;
				margin-bottom: 1rem;
			}

			&-text {
				font-family: $font-texts;
				font-weight: 400;
				font-size: 18px;
				line-height: 25px;
			}

			&:not(:first-child):after {
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				display: inline-block;
				height: 100%;
				width: 2px;
				background: linear-gradient(
					180deg,
					rgba(171, 174, 183, 0) 0%,
					#abaeb7 51.56%,
					rgba(171, 174, 183, 0) 100%
				);
			}

			@media (max-width: $xl) {
				&:nth-child(3)::after {
					display: none;
				}
				width: 50%;
			}

			@media (max-width: $md) {
				&::after {
					display: none !important;
				}

				width: 100%;
			}
		}
	}
}
.container {
}
